import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function organizationReducer(state = { ...initialState, token: '' }, action) {
  switch (action.type) {
    case types.GET_EMBED_CODE_SUCCESS:
      return {
        ...state, data: action.payload, token: action.token, inProgress: false,
      };
    case types.GET_EMBED_CODE_PENDING:
      return { ...state, inProgress: true, error: '' };
    case types.GET_EMBED_CODE_FAILED:
      return { ...state, error: action.error, inProgress: false };

    case types.GET_LIVE_VERSION_SUCCESS:
      return {
        ...state, data: action.payload, token: action.token, inProgress: false,
      };
    case types.GET_LIVE_VERSION_PENDING:
      return { ...state, inProgress: true, error: '' };
    case types.GET_LIVE_VERSION_FAILED:
      return { ...state, error: action.error, inProgress: false };
    default:
      return state;
  }
}
