import * as types from '../actions/actionTypes';

export default function MenuReducer(state = { openSection: '', show: true, showMobile: false }, action) {
  switch (action.type) {
    case types.SET_MENU_OPEN_SECTION:
      return { ...state, openSection: action.payload };
    case types.TOGGLE_MENU_SHOWN:
      return { ...state, show: action.payload };
    case types.TOGGLE_MOBILE_MENU_SHOWN:
      return { ...state, showMobile: action.payload };
      case types.GET_MODULES_FOR_MENU:
      return { ...state, modules: action.payload };
    default:
      return state;
  }
}
