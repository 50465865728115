import * as types from '../actions/actionTypes';

export default function IntegrationsReducer(state = {}, action) {
  switch (action.type) {
    case types.SEARCH_STREAMS_PENDING:
      return { inProgress: true };
    case types.SEARCH_STREAMS_SUCCESS:
      return { inProgress: false, results: action.payload };
    case types.SEARCH_STREAMS_FAILED:
      return { inProgress: false, cserror: (action.error !== '' ? action.error : 'An error occurred.') };

    case types.CHECK_SF_EMAIL_SUCCESS:
        return { sfLookupInProgress: false, sfMemberId: action.payload.id};
    case types.CHECK_SF_EMAIL_PENDING:
        return { sfLookupInProgress: true };
    case types.CHECK_SF_EMAIL_FAILED:
        return { sfLookupInProgress: false, sferror: action.error };
    default:
      return state;
  }
}
