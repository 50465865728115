import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function projectVersionReducer(state = {
  ...initialState,
}, action) {
  switch (action.type) {
    case types.GET_PROJECT_VERSION_SUCCESS:
      return {
        updatedNew: !action.payload.sameAsLive,
      };
    case types.UPDATE_PROJECT_VERSION_SUCCESS:
      return {
        updatedNew: true,
      };
      case types.PUBLISH_PROJECT_SUCCESS:
      return {
        updatedNew: false,
      };
    case types.CROP_IMAGE_CROP_SUCCESS:
      return {
        updatedNew: true,
      };
    case types.DELETE_ALL_CROPPED_IMAGES_CROP_SUCCESS:
      return{
        updatedNew: true,
      };
    case types.UPDATE_MODULES_SUCCESS:
      return {
       updatedNew: true,
      };
    case types.UPDATE_MODULE_SUCCESS:
      return {
       updatedNew: true,
      };
    case types.UPDATE_MENUITEM_PARAMETER_SUCCESS:
      return {
          updatedNew: true,
      };

      case types.UPDATE_MENUITEMS_SUCCESS:
          return {
              updatedNew: true,
          };
    case types.UPDATE_PROJECT_VERSION_PARAMETER_SUCCESS:
      return {
        updatedNew: true,
      };
    case types.UPDATE_MODULE_PARAMETER_SUCCESS:
      return {
        updatedNew: true,
      };
    default:
      return state;
  }
}
