import React from 'react';
import withLogging from '../logger';
import { logger } from '../logger';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    //If this creates too much spam, let's put an if statement in which doesn't push
    // if window.location.hostname == 'localhost', but it could be useful when collaborating
    // to include localhost logs
      logger.trackEvent({ name: 'FrontEndExceptionDetails', properties: { error, errorInfo, source:window.location.hostname } });
  }

  render() {
    if (this.state.error) {
      // Fallback UI if an error occurs
      return (
            <div className='content-inside'>
                <h1>Oops! Something went wrong!</h1>
                <p className='lead'>Please try reloading the page, or contact support.</p>
            </div>
      );
    }
    return this.props.children;
  }
}
export default withLogging(ErrorBoundary);
