import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function mediaReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_PROJECT_MEDIA_FAILED:
        return { ...state, error: action.error, inProgress:false }
    case types.GET_PROJECT_MEDIA_SUCCESS:
      return { ...state, select: action.payload, inProgress:false };
    case types.GET_PROJECT_MEDIA_PENDING:
      return { ...state, inProgress: true };
    case types.DISCONNECT_MEDIA_SUCCESS:
     return {...state, select: {}};
    default:
      return state;
  }
}
