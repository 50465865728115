import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function menuItemReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_PROJECT_VERSION_MENUITEMS_SUCCESS:
      return { ...state, data: action.payload.data, inProgress: false };
    case types.GET_PROJECT_VERSION_MENUITEMS_PENDING:
      return { ...state, inProgress: true };
    case types.GET_PROJECT_VERSION_MENUITEMS_FAILED:
      return {
        ...state, error: action.error, data: [], inProgress: false,
      };
    case types.UPDATE_MENUITEMS_SUCCESS:
      return {
        ...state, select: action.payload, inProgress: false, justSaved: true, error: '',
      };
    case types.UPDATE_MENUITEMS_PENDING:
      return {
        ...state, inProgress: true, justSaved: false, error: '',
      };
    case types.UPDATE_MENUITEMS_FAILED:
      return { ...state, inProgress: false, error: action.error };
    case types.SHOW_NOTIFICATION:
      return { ...state, justSaved: true, error: '' };
    case types.HIDE_NOTIFICATION:
      return { ...state, justSaved: false, error: '' };
    default:
      return state;
  }
}
