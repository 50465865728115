import { compose, createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers/rootReducer';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';

export default function configureStore(history) {
  const routerReduxMiddleware = routerMiddleware(history);

  let middleware = applyMiddleware(
    thunk,
    loadingBarMiddleware({
      promiseTypeSuffixes: ['PENDING', 'SUCCESS', 'FAILED'],
    }),
    routerReduxMiddleware,
  );
  if (window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined) {
    middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(
    rootReducer,
    {},
    middleware,
  );
}
