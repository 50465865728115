import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function CloverReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CLOVER_PRODUCTS_PENDING:
      return { ...state, inProgress: true, error: '' };
    case types.GET_CLOVER_PRODUCTS_SUCCESS:
      return {
        ...state, data: action.payload, inProgress: false, token: action.token,
      };
    case types.GET_CLOVER_PRODUCTS_FAILED:
      return { ...state, inProgress: false, error: action.error };
    case types.GET_CLOVER_SELECTED_PRODUCT_SUCCESS:
      return { ...state, select: action.payload, inProgress: false };
    case types.GET_CLOVER_PAGES_SUCCEED:
      return {
        ...state, pages: action.pages, payload: action.payload, select: action.select, inProgress: false, token: action.token,
      };
    case types.GET_CLOVER_PAGES_FAILURE:
      return { ...state, inProgress: false, error: action.error };
    case types.CLEAR_CLOVER_OBJECTS:
      return {
        ...state, data: [], select: {}, pages: [], payload: {}, token: action.token, error: '',
      };
    default:
      return state;
  }
}
