import * as types from '../actions/actionTypes';
import initialCropState from './initialCropState';

export default function cropReducer(state = { ...initialCropState, successMessage: '' }, action) {
  switch (action.type) {
    // RESET WARNING
    case types.RESET_WARNING_SUCCESS:
      return { ...state, wrongResolution: false };
    case types.RESET_WARNING_PENDING:
      return {
        ...state, inProgress: true, error: '', wrongResolution: false,
      };
    case types.RESET_WARNING_FAILED:
      return {
        ...state, error: action.error, inProgress: false, wrongResolution: false,
      };

      // RESET CROPPED IMAGE
    case types.RESET_CROPPED_IMAGE:
      return { ...state, croppedImage: '' };


      // CROP IMAGE
    case types.CROP_IMAGE_CROP_SUCCESS:
      return {
        ...state, croppedImage: {imagePath: action.payload.imagePath}, modal: false, hasCroppedImage: true, isRecrop: false,
      };
    case types.CROP_IMAGE_CROP_PENDING:
      return { ...state, inProgress: true, error: '' };
    case types.CROP_IMAGE_CROP_FAILED:
      return {
        ...state, error: action.error, inProgress: false, hasCroppedImage: false,
      };

      // GET LATEST ASSET ID
    case types.GET_LATEST_ASSET_CROP_SUCCESS:
      return { ...state, latestAssetId: action.payload };
    case types.GET_LATEST_ASSET_CROP_PENDING:
      return { ...state, inProgress: true, error: '' };
    case types.GET_LATEST_ASSET_CROP_FAILED:
      return { ...state, error: action.error, inProgress: false };

      // GET LATEST SPLASH ASSET ID
    case types.GET_LATEST_SPLASH_ASSET_CROP_SUCCESS:
      return { ...state, latestAssetId: action.payload };
    case types.GET_LATEST_SPLASH_ASSET_CROP_PENDING:
      return { ...state, inProgress: true, error: '' };
    case types.GET_LATEST_SPLASH_ASSET_CROP_FAILED:
      return { ...state, error: action.error, inProgress: false };

      // UPLOAD ORIGINAL IMAGE TO AZURE
    case types.UPLOAD_ASSET_IMAGE_CROP_SUCCESS:
      return {
        ...state,  originalImage: action.payload, modal: true, isRecrop: action.crop, wrongResolution: false,
      };
    case types.UPLOAD_ASSET_IMAGE_CROP_PENDING:
      return { ...state, inProgress: true };
    case types.UPLOAD_ASSET_IMAGE_CROP_FAILED:
      return {
        ...state, error: action.error, inProgress: false, modal: false, isRecrop: action.crop, wrongResolution: true,
      };
    default:
      return state;

      // GET CROP ORIGINAL IMAGE
    case types.GET_CROPPED_IMAGE_CROP_SUCCESS:
      return {
        ...state, croppedImage: action.payload, hasCroppedImage: true,
      };
    case types.GET_CROPPED_IMAGE_CROP_PENDING:
      return { ...state, inProgress: true };
    case types.GET_CROPPED_IMAGE_CROP_FAILED:
      return {
        ...state, inProgress: false, error: action.error, hasCroppedImage: false,
      };

      // GET LOGO
    case types.GET_LOGO_CROP_SUCCESS:
      return {
        ...state, logo: action.payload, logoCheckboxDisabled: false,
      };
    case types.GET_LOGO_CROP_PENDING:
      return { ...state, inProgress: true };
    case types.GET_LOGO_CROP_FAILED:
      return {
        ...state, inProgress: false, error: action.error, logo: {}, logoCheckboxDisabled: true,
      };

      // GET ORIGINAL IMAGE
    case types.GET_ORIGINAL_IMAGE_CROP_SUCCESS:
      return {
        ...state, originalImage: action.payload, modal: true, isRecrop: true,
      };
    case types.GET_ORIGINAL_IMAGE_CROP_PENDING:
      return { ...state, inProgress: true };
    case types.GET_ORIGINAL_IMAGE_CROP_FAILED:
      return {
        ...state, inProgress: false, error: action.error, modal: false,
      };
    case types.GET_ORIGINAL_IMAGE_CROP_CANCEL_SUCCESS:
      return {
        ...state, inProgress: false, modal: false,
      };

      // DELETE ALL IMAGES: CROPPED OR/AND ORIGINAL
    case types.DELETE_ALL_CROPPED_IMAGES_CROP_SUCCESS:
      return {
        ...state, latestAssetId: action.payload, hasCroppedImage: false, croppedImage: null, modal: false,
      };
    case types.DELETE_ALL_CROPPED_IMAGES_CROP_PENDING:
      return { ...state, inProgress: true, deletingImages: true };
    case types.DELETE_ALL_CROPPED_IMAGES_CROP_FAILED:
      return {
        ...state, inProgress: false, error: action.error, hasCroppedImage: true,
      };

      // CREATE NEW IMAGE
    case types.CREATE_IMAGE_CROP_SUCCESS:
      return { ...state, dbImage: action.payload };
    case types.CREATE_IMAGE_CROP_PENDING:
      return { ...state, inProgress: true };
    case types.CREATE_IMAGE_CROP_FAILED:
      return { ...state, inProgress: false, error: action.error };

      // RECROP
    case types.RECROP_CROP_SUCCESS:
      return {
        ...state, modal: true, wrongResolution: false,
      };
    case types.RECROP_CROP_PENDING:
      return { ...state, inProgress: true };
    case types.RECROP_CROP_FAILED:
      return {
        ...state, inProgress: false, error: action.error, modal: false,
      };

      // DELETE SINGLE IMAGE
    case types.DELETE_SINGLE_IMAGE_CROP_SUCCESS:
      return {
        ...state, isRecrop: false, modal: false,
      };
    case types.DELETE_SINGLE_IMAGE_CROP_PENDING:
      return { ...state, inProgress: true };
    case types.DELETE_SINGLE_IMAGE_CROP_FAILED:
      return { ...state, inProgress: false, error: action.error };
  }
}
