
export const setToken = () => {
  if (typeof (Storage) !== 'undefined' && localStorage.getItem('AuthToken')) {
    window.genesisToken = () => { return localStorage.getItem('AuthToken') };
  }
  else{
      window.genesisToken = () => { return null };
  }
};

