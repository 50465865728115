import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { LOCATION_CHANGE } from 'react-router-redux/reducer';

const clearAlertsForState = (state, location) => {
  if (state && state.data) {
    return state.data.filter(a => a.global || location.pathname.indexOf(a.view) > 0);
  }
  return [];
};

export default function alertReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const data = clearAlertsForState(state, action.payload);
      return { ...state, data };
    case types.CREATE_IMAGE_CROP_SUCCESS:
      const crop = clearAlertsForState(state.data, action.location);
      return { ...state, data: crop };
    case types.CLEAR_ALERT:
      return { ...state, data: action.payload };
    case types.FIRE_ALERT:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
