import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function fmsReducer(state = { ...initialState, filtered: [] }, action) {
  switch (action.type) {
    case types.GET_FORMS_SUCCESS:
      return {
        ...state, filtered: action.payload, inProgress: false,
      };
    case types.GET_FORMS_PENDING:
      return { ...state, filtered: [], inProgress: true };
    case types.GET_FORMS_FAILED:
      return { ...state, inProgress: true, error: '' };
    case types.GET_FORMS_CATEGORIES:
      return { ...state, categories: action.payload };
    default:
      return state;
  }
}
