import { combineReducers } from 'redux';

import AuthReducer from './auth-reducer';
import MediaReducer from './media-reducer';
import ModulesReducer from './moduleReducer';
import ProfileReducer from './profileReducer';
import ProjectConfigurationReducer from './proj-conf-reducer';
import ProjectVersionReducer from './proj-version-reducer';
import ProjectReducer from './project-reducer';
import TemplateReducer from './TemplateReducer';
import FMSReducer from './FMSReducer';
import AlertReducer from './AlertReducer';
import CropReducer from './CropReducer';
import CloverReducer from './CloverReducer';
import MenuReducer from './MenuReducer';
import PublishReducer from './PublishReducer';
import MessageReducer from './messageReducer';
import OrganizationReducer from './OrganizationReducer';
import IntegrationsReducer from './integrationreducer';
import FlagsReducer from './flagsreducer';
import MenuItemReducer from './menuItemReducer';
import VimeoReducer from './VimeoReducer';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { routerReducer } from 'react-router-redux';



const appReducer = combineReducers({
  auth: AuthReducer,
  crop: CropReducer,
  media: MediaReducer,
  modules: ModulesReducer,
  menuItems: MenuItemReducer,
  proj_conf: ProjectConfigurationReducer,
  projects: ProjectReducer,
  versions: ProjectVersionReducer,
  templates: TemplateReducer,
  profiles: ProfileReducer,
  forms: FMSReducer,
  alerts: AlertReducer,
  loadingBar: loadingBarReducer,
  router: routerReducer,
  clover: CloverReducer,
  menu: MenuReducer,
  organization: OrganizationReducer,
  publish: PublishReducer,
  messages: MessageReducer,
  integrations: IntegrationsReducer,
  flags: FlagsReducer,
  vimeo: VimeoReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'CHANGE_PROJECTS') {
    const { router, profiles, flags, alerts } = state;
    alerts.data = alerts.data.filter(alert => alert.id == 'apple');
    state = { router, profiles, flags, alerts };
  }
  if (action.type === 'LOG_OUT') {
      const { router } = state;
      state = {router};
  }

  return appReducer(state, action)
}
export default rootReducer;
