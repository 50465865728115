import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function templateReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_TEMPLATES_SUCCESS:
      return { ...state, data: action.payload, inProgress: false };
    case types.GET_TEMPLATES_PENDING:
      return { ...state, inProgress: true, error: '' };
    case types.GET_TEMPLATES_FAILED:
      return { ...state, error: action.error, inProgress: false };
    case types.GET_TEMPLATE_SUCCESS:
      return { ...state, select: action.payload, inProgress: false };
    case types.GET_TEMPLATE_PENDING:
      return { ...state, inProgress: true, error: '' };
    case types.GET_TEMPLATE_FAILED:
      return { ...state, error: action.error, inProgress: false };
    default:
      return state;
  }
}
