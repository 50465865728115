import * as types from '../actions/actionTypes';

let initialState = {
  liveStreaming:false,
  chMSIntegration:false,
  chMSCheckIn: false,
  eventMessaging: false,
  shareFaithDevotionals: false
};

export default function FlagsReducer(state = 
  {
    inProgress: false,
    results: initialState
  }, action) {
  switch (action.type) {
    case types.GET_FLAGS_PENDING:
      return {  inProgress: true, results:initialState };
    case types.GET_FLAGS_SUCCESS:
      return { inProgress: false, results: action.payload };
    case types.GET_FLAGS_FAILED:
      return { inProgress: false, error: action.error };
    default:
      return state;
  }
}
