import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function VimeoReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_VIMEO_FEEDS_LIST_SUCCESS:
      return {
        ...state, data: action.payload.data,
      };
    case types.GET_VIMEO_FEEDS_LIST_FAILED:
      return {
        ...state, data: [],
      };
    default:
      return state;
  }
}
