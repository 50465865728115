import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function projectVersionReducer(state = {
  ...initialState, validationInProgress: false, validationError: '', successMessage: '', submission_version: 0,
}, action) {
  switch (action.type) {
    case types.CREATE_PROJECT_SUCCESS:
      return {
        ...state, data: action.payload.data.latestVersion, isNew: true, projectId: action.payload.ids.newProjectId, inProgress: false,
      };
    case types.CREATE_PROJECT_VERSION_SUCCESS:
      return {
        ...state, data: action.payload, isNew: true, projectId: action.projectId, inProgress: false,
      };
    case types.CREATE_PROJECT_VERSION_PENDING:
      return { ...state, inProgress: true, isNew: false };
    case types.CREATE_PROJECT_VERSION_FAILED:
      return {
        ...state, data: action.payload, inProgress: false, isNew: true,
      };
    case types.GET_PROJECT_VERSION_SUCCESS:
      return {
        ...state, select: action.payload, inProgress: false, updatedNew: !action.payload.live, isNew: false,
      };
    case types.GET_PROJECT_VERSION_PENDING:
      return {
        ...state, inProgress: true, select: {}, appStoreNameIsValid: true,
      };
    case types.GET_PROJECT_VERSION_FAILED:
      return { ...state, error: action.error, inProgress: false };

    case types.UPDATE_PROJECT_VERSION_SUCCESS:
      return {
        ...state, select: action.payload, inProgress: false, error: '', justSaved: true, updatedNew: true
      };

    case types.UPDATE_PROJECT_VERSION_PENDING:
      return {
        ...state, inProgress: true, error: '', updated: false, justSaved: false,
      };
    case types.UPDATE_PROJECT_VERSION_FAILED:
      return { ...state, inProgress: false, error: action.error };

      case types.GET_PROJECT_MEDIA_FAILED:
          return { ...state, inProgress: false, select: {...state.select, cloverAccountEmail: ''}};
    case types.DELETE_PROJECT_VERSION_SUCCESS:
      return { ...state, deleted: true };

    case types.DELETE_PROJECT_VERSION_FAILED:
      return { ...state, deleted: false };

    case types.GET_PROJECT_CONFIG:
      return { ...state, select: action.payload };
    case types.PUBLISH_PROJECT_PENDING:
      return {
        ...state, publishFailed: false, inProgress: true, publishProgress: true,
      };
    case types.PUBLISH_PROJECT_FAILED:
      return {
        ...state, publishFailed: true, inProgress: false, publishProgress: false,
      };
    case types.PUBLISH_PROJECT_SUCCESS:
      return {
        ...state, select: action.payload, updatedNew: false, publishFailed: false, inProgress: false, publishProgress: false,
      };
    case types.CROP_IMAGE_CROP_SUCCESS:
      return {
        ...state, updatedNew: true
      };

    case types.DELETE_ALL_CROPPED_IMAGES_CROP_SUCCESS:
      return{
        ...state, updatedNew: true
      };
    case types.CANCEL_SUBMISSION_PENDING:
      return { ...state, cancelFailed: false, inProgress: true };
    case types.CANCEL_SUBMISSION_FAILED:
      return {
        ...state, select: action.payload, cancelFailed: true, inProgress: false,
      };
    case types.CANCEL_SUBMISSION_SUCCESS:
      return {
        ...state, select: action.payload, cancelFailed: false, inProgress: false,
      };
    case types.UPDATE_STATUS:
      return { ...state, select: action.payload };

    case types.TOGGLE_PREVIEW_SHOWN:
      return { ...state, showPreview: action.payload };

    default:
      return state;
  }
}
